import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import BlogPage from './blog'

export const IndexPage = ({ data }) => {
  return <BlogPage data={data} />
}

IndexPage.defaultProps = {}

IndexPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 1000, sort: { fields: frontmatter___date }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
